import { API_URL, NEW_API_URL } from "./Config";
import {
  GenericMessage,
  GetParticipantCount,
  NewTrainingDataResponse,
  TrainingAttendanceResponse,
  TrainingData,
} from "./Descriptors";

import ky, { Options } from "ky";
import { getToken } from "./Login";
import { KyInstance } from "ky/distribution/types/ky";

export class TrainingClient {
  api: KyInstance;

  constructor() {
    this.api = ky.extend({
      hooks: {
        beforeRequest: [
          (request) => {
            if (getToken()) {
              request.headers.set("Authorization", `Bearer ${getToken()}`);
            }
          },
        ],
      },
    });
  }

  getTrainingAttendance = async (year: number, isElite: boolean): Promise<TrainingAttendanceResponse[]> => {
    return await this.api.get(`${API_URL}/attendance/yearly/${year}/?elite=${isElite.toString()}`).json();
  }

  getAll = async ({
    pageNumber,
    pageSize,
  }: {
    pageNumber: number;
    pageSize: number;
  }): Promise<TrainingData[]> => {
    const options: Options = {
      searchParams: {
        pageNumber,
        pageSize,
      },
    };
    return await this.api.get(`${NEW_API_URL}/trainings`, options).json();
  };

  async getSingleTraining(id: string): Promise<TrainingData> {
    return (await this.api
      .get(`${API_URL}/trainings/${id}/`)
      .json());
  }

  async createNewTraining(payload: any) {
    return (await this.api
      .post(`${API_URL}/trainings/`, { json: payload })
      .json()) as NewTrainingDataResponse;
  }

  async deleteTraining(trainingId: number) {
    return (await this.api
      .delete(`${API_URL}/trainings/${trainingId}/`)
      .json()) as GenericMessage;
  }

  async getParticipantCount(startingDate: Date, endingDate: Date) {
    return (await this.api
      .get(`${API_URL}/trainings/participant-count/`, {
        searchParams: {
          starting_date: startingDate.toISOString(),
          ending_date: this.addOneDay(endingDate).toISOString(),
        },
      })
      .json()) as GetParticipantCount;
  }

  addOneDay = (date: Date) => {
    const newDate = new Date(date);
    newDate.setDate(newDate.getDate() + 1);
    return newDate;
  };
}
