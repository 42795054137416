import {
  Text,
  Image,
  Container,
  Stack,
  Title,
  Flex,
  Anchor,
} from "@mantine/core";

export const SocksGuide = () => {
  return (
    <Container size="md" my={20}>
      <Stack p="xl">
        {/* Title */}
        <Title order={1} fw={900} ta="left">
          Lábvédelem - Neoprén és sima zokni
        </Title>

        <Text size="md" ta="justify">
          Mivel a Najade és a Muréna uszonyok is csak korlátozott számú méretben
          és egy lábrész formával kaphatóak, ezért ritka, hogy egy játékos
          lábfejére tökéletesen illene. Előfordulhat, hogy a méret nem stimmel
          és túl nagy az uszony hosszra vagy szélességre, vagy a terhelés nem
          megfelelően oszlik el és jobban nyomja a lábfej tetejét vagy a
          lábujjakat az uszony. Szerencsére ezekre a problémákra megoldást tud
          nyújtani egy jól megválasztott zokni.
        </Text>

        <Text size="md" ta="justify">
          Három jellemző megoldás van a csapatban: a sima, utcai zokni, a
          szilikonnal megerősített utcai zokni és a boltban kapható neoprén
          zokni.
        </Text>

        <Title order={3} fw={900} mt="1rem" ta="left">
          Sima zokni
        </Title>

        <Text size="md" ta="justify">
          Akinek csak kicsi korrekcióra van szüksége, annak általában elég egy
          régi zoknit beáldoznia. Kicsi bőrlehorzsolások, vagy nagyon pici
          méreteltérés esetén ez bőven elég tud lenni. Ha úgy érzed, hogy
          kellemetlen az uszony a bőrödnek, de amúgy a nem lötyög nagyon és nem
          nyomja a lábad, akkor ezzel érdemes első körben próbálkozni.
        </Text>

        <Title order={3} fw={900} mt="1rem" ta="left">
          Szilikonnal megerősített zokni
        </Title>

        <Text size="md" ta="justify">
          Ez egy olcsóbb alternatívája lehet a neoprén zokninak, ám több
          munkával is jár. Ahogy kesztyűkészítésnél, itt is boltban kapható
          szilikonnal átkenjük a zoknit, amivel ellenállóbbá és vastagabbá
          tesszük. A csapatban Árpi használ csak ilyet, hozzá érdemes fordulni,
          ha ez a megoldás érdekel.
        </Text>

        <Title order={3} fw={900} mt="1rem" ta="left">
          Neoprén zokni
        </Title>

        <Text size="md" ta="justify">
          A Najade forgalmaz kifejezetten búvársportokhoz neoprén zoknit. Ezeket
          már mindenki láthatta edzése: csak bizonyos pontokon fedi a lábat,
          ahol az az uszonnyal érintkezik. Két különböző kivitelben árulják
          aszerint, hogy kinek mekkora védelemre van szüksége, valamint két
          különböző vastagságban. Sok csapattagunk használja valamelyiket a
          kapható zoknik közül, edzésen érdemes kipróbálni őket rendelés előtt.
        </Text>

        <Text size="md" ta="justify">
          A neoprén zoknikat{" "}
          <Anchor href="https://www.najadefins.org/product/finsocks">
            {">>Najade webshopjában<<"}
          </Anchor>{" "}
          tudod megnézni. Vásárlás előtt érdemes egyeztetni a csapattal,
          elképzelhető hogy jár a búvársportolóknak valamilyen kedvezmény.
        </Text>

        <Flex>
          <Stack mx={"auto"}>
            <Image
              src="https://uwh-webpage-pictures.s3.us-east-1.amazonaws.com/felszereles/zokni_najade_1.jpg"
              alt="Rövid kivitel"
              radius="md"
              h={250}
              mx="auto"
            />
            <Text fs={"italic"} size="sm">
              Csak lábujjakat védő zokni
            </Text>
          </Stack>
          <Stack mx={"auto"}>
            <Image
              src="https://uwh-webpage-pictures.s3.us-east-1.amazonaws.com/felszereles/zokni_najade_2.jpg"
              alt="Hosszú kivitel"
              radius="md"
              h={250}
              mx="auto"
            />
            <Text fs={"italic"} size="sm">
              Teljes lábfejet védő zokni
            </Text>
          </Stack>
        </Flex>
        <Title order={3} fw={900} mt="1rem" ta="left">
          +1 lehetőség - szigszalag
        </Title>

        <Text size="md" ta="justify">
          Akinek szinte tökéletes az uszony, de hosszú edzés, verseny után a
          lábujjai fájnak, megsérül a bőr, az használhat még szigetelőszalagot
          védelem gyanánt. Ezzel csak körbe kell tekerni a problémás részeket és
          az edzés végeztével letépni.
        </Text>
      </Stack>
    </Container>
  );
};
