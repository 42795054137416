import { useMutation, useQuery, useQueryClient } from "react-query";
import { NewTrainingDataResponse, TrainingAttendanceResponse, TrainingData } from "../api/Descriptors";
import { TrainingClient } from "../api/Trainings";

const trainingsClient = new TrainingClient();


type NewTrainingTeam = {
  black_team: number[],
  white_team: number[],
  swimming_players: number[],
}

export const useTrainings = () => {
  const queryClient = useQueryClient()

  const useTraining = (trainingId: number | string) => {
    if (typeof trainingId === "string") {
      trainingId = parseInt(trainingId);
      if (Number.isNaN(trainingId)) {
        throw new Error(`trainingId ('${trainingId}') is not a number`);
      }
    }

    return useQuery<TrainingData, Error>({
      queryKey: ["trainings", trainingId],
      queryFn: () => trainingsClient.getSingleTraining(trainingId.toString()),
    });
  };

  const useCreateTrainingTeam = () => useMutation<NewTrainingDataResponse, Error, NewTrainingTeam>(
    async (payload: NewTrainingTeam) => await trainingsClient.createNewTraining(payload),
    {
      onSuccess: () => {
        queryClient.invalidateQueries(["trainings"]);
      },
    },
  )

  const useTrainingAttendance = (year: number, isElite: boolean) => {
    return useQuery<TrainingAttendanceResponse[], Error>({
      queryKey: ["trainings", "attendance", year, isElite],
      queryFn: () => trainingsClient.getTrainingAttendance(year, isElite)
    });

  }

  const useAllTrainings = ({
    pageSize,
    pageNumber,
  }: {
    pageSize: number;
    pageNumber: number;
  }) =>
    useQuery<TrainingData[], Error>({
      queryKey: ["trainings", pageSize, pageNumber],
      queryFn: () => trainingsClient.getAll({ pageSize, pageNumber }),
    });

  return {
    useTraining,
    useAllTrainings,
    useCreateTrainingTeam,
    useTrainingAttendance,
  };
};
