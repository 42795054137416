import React, { useState } from 'react';
import { Table, Text, Paper, Loader, Select, Checkbox, Flex, Chip, Group, Switch } from '@mantine/core';
import { useTrainings } from '../../hooks/useTrainings';

const MONTHS = [
  'Január', 'Február', 'Március', 'Április', 'Május', 'Június',
  'Július', 'Augusztus', 'Szeptember', 'Október', 'November', 'December',
  'Σ'
];

// Calculate color based on attendance - deeper green for higher attendance
const getAttendanceColor = (value: number) => {
  if (value === 0) return 'rgba(255, 200, 200, 0.3)'; // Light red for zero attendance
  // Create a gradient from light to dark green based on attendance
  const intensity = Math.min((value / 9) * 0.8, 0.8); // Max at 9 trainings
  return `rgba(0, 255, 0, ${intensity})`;
};


export const TrainingAttendance = () => {
  const { useTrainingAttendance } = useTrainings();
  const [year, setYear] = useState(new Date().getFullYear())
  const [isElite, setIsElite] = useState(false)
  const { data, refetch } = useTrainingAttendance(year, isElite)
  const myblue = "var(--mantine-color-myblue-3)";

  if (!data) {
    return <Loader />
  }

  return (
    <>
      <Group>
        <Select defaultValue={year.toString()} data={["2020", "2021", "2022", "2023", "2024", "2025"]}
          onChange={(_value, option) => {
            setYear(parseInt(option.value))
            refetch();
          }}
        />
        <Switch
          label={"Csak elite?"}
          checked={isElite}
          onChange={(event) => setIsElite(event.currentTarget.checked)}
          color={myblue}
        />
      </Group>
      <Paper shadow="sm" p="md">
        <Table
          striped
          highlightOnHover
          withTableBorder
          withColumnBorders
          style={{ overflowX: 'auto', whiteSpace: 'nowrap' }}
        >
          <Table.Thead>
            <Table.Tr>
              <Table.Th>
                <Text fw={700}>Player Name</Text>
              </Table.Th>
              {MONTHS.map((month) => (
                <Table.Th key={month} style={{ textAlign: 'center', minWidth: '80px' }}>
                  <Text fw={700}>{month}</Text>
                </Table.Th>
              ))}
            </Table.Tr>
          </Table.Thead>
          <Table.Tbody>
            {data.map((player) => (
              <Table.Tr key={player.player_id}>
                <Table.Td>
                  <Text fw={500}>{player.player_full_name}</Text>
                </Table.Td>
                {player.trainings_per_month.map((count, index) => (
                  <>
                    <Table.Td
                      key={index}
                      style={{
                        backgroundColor: getAttendanceColor(count),
                        textAlign: 'center',
                        transition: 'background-color 0.2s ease'
                      }}
                    >
                      <Text fw={500}>{count}</Text>
                    </Table.Td>
                  </>
                ))}
                <Table.Td key={player.player_id + "_sum"}>
                  {player.trainings_per_month.reduce((acc, curr) => { return acc + curr }, 0)}
                </Table.Td>
              </Table.Tr>
            ))}
          </Table.Tbody>
        </Table>
      </Paper>
    </>
  );
};
